.formInput {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: stretch;
   font-size: 1rem;
   margin-bottom: 1rem;
}
.label {
   font-size: 1rem;
   font-weight: 500;
   margin-bottom: 0.5rem;
}
.input {
   height: 56px;
   padding: 1rem;
   border: 1px solid rgb(202, 203, 203);
   border-radius: 0.5rem;
   font-size: 1rem;
   color: #616264;
}
.input::placeholder {
   font-family: "Poppins", "sans-serif";
   font-weight: 400;
   font-size: 1rem;
   color: rgba(202, 203, 203, 1);
}
.contact {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.contact .formInput {
   width: 48%;
}
.textarea {
   resize: none;
   width: 100%;
   height: 170px;
   padding: 1rem;
   border: 1px solid rgb(202, 203, 203);
   border-radius: 0.5rem;
   font-size: 1rem;
   color: #616264;
}
.textarea::placeholder {
   font-family: "Poppins", "sans-serif";
   font-weight: 400;
   font-size: 1rem;
   color: rgba(202, 203, 203, 1);
}
.checkboxesLabel {
   font-size: 1rem;
   font-weight: 500;
   margin-bottom: 1rem;
}
.checkboxWraper {
   margin-bottom: 0.5rem;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

.checkbox {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 1rem;
   height: 1rem;
   border: 1px solid rgb(202, 203, 203);
   appearance: none;

   border-radius: 4px;
   margin-right: 0.5rem;
}
.checkbox::before {
   content: "";
   width: 0.5rem;
   height: 0.5rem;
   border-radius: 1px;
   transform: scale(0);
   transition: 120ms transform ease-in-out;
   background-color: rgba(0, 179, 181, 1);
}
.checkbox:checked::before {
   transform: scale(1);
}
.checkboxLabel {
   max-width: 90%;
   color: #616264;
}

.agreement {
   display: flex;
   justify-content: space-between;
   margin-bottom: 2rem;
   margin-top: 1rem;
}
.agreementInput {
   appearance: none;
   border: 1px solid rgb(202, 203, 203);

   width: 1rem;
   height: 1rem;
   margin-right: 0.5rem;
}
.agreementLabel {
   font-size: 11px;
   width: 95%;
}
.subbmit {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 1rem 2rem;
   border-radius: 50px;
   border: none;
   background-color: rgba(0, 179, 181, 1);
   color: white;
   cursor: pointer;
}
.arrow {
   width: 20px;
   height: 20px;
   margin-left: 1rem;
}
.subbmit:disabled {
   background-color: #616264;
}

.errorMsg {
   padding: 0.5rem 0;
   color: red;
}
