.button {
   display: block;
   text-align: center;
   padding: 1rem 2rem;
   border-radius: 50px;
   background-color: transparent;
   color: rgba(44, 46, 49, 0.75);
   border: 1px solid rgba(44, 46, 49, 0.25);
   font-size: 16px;
   font-family: "Poppins";
   font-weight: 300;
}
.button:hover {
   background-color: rgba(245, 245, 245, 1);
}
