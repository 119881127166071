.wraper {
   margin: 0 auto;
   max-width: 50rem;
   text-align: center;
   margin-bottom: 2rem;

   display: flex;
   flex-direction: column;
   align-items: center;
}
.icons {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 2rem;
}
.icon {
   margin-left: -0.5rem;
}

.paragrafOne {
   text-align: center;
   color: #2c2e31;
   line-height: 32px;
   margin-bottom: 1rem;
}
.paragrafTwo {
   text-align: center;
   color: #2c2e31;
   line-height: 32px;
   margin-bottom: 4rem;
}
.paragrafTwo a {
   color: rgba(0, 179, 181, 1);
}
