.wraper {
   max-width: 1200px;
   padding: 32px 12px 0 12px;
   margin: 0 auto;
}
.link {
   color: rgba(0, 179, 181, 1);
   width: 15rem;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-bottom: 32px;
}
.linkArrow {
   width: 20px;
   height: 20px;
   margin-right: 1rem;
}

.linkArrow img {
   width: 100%;
}
.title {
   font-size: 4rem;
   font-weight: 500;
   color: #2c2e31;
   margin-bottom: 4rem;
}
.sub {
   font-size: 1.75rem;
   font-weight: 600;
   color: #2c2e31;
   margin-bottom: 1.5rem;
   position: relative;
}
.decor {
   width: 20rem;
   height: 1rem;
   background: linear-gradient(to left, rgba(0, 179, 181, 1), rgba(255, 253, 90, 1), rgba(85, 233, 137, 0));

   position: absolute;
   bottom: 0;
   left: 0;
   transform: translateY(-50%);

   z-index: -1;
}
.klasa {
   font-size: 20px;
}
ul {
   margin-bottom: 2rem;
}
li {
   margin-bottom: 8px;
}
li:hover {
   background-color: aliceblue;
}

li span:nth-last-child(1) {
   margin-left: 1rem;
}
