.wraper {
   position: relative;
}
.person {
   width: 78px;
   height: 78px;
   margin: 0.5rem;
   border-radius: 50%;
   overflow: hidden;

   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
.person img {
   width: 100%;
}
.name {
   position: absolute;
   bottom: -1.9rem;
   left: 50%;
   transform: translateX(-50%);
   background-color: rgba(0, 179, 181, 1);
   padding: 10px 14px;
   border-radius: 50px;
   opacity: 0;
   z-index: 2;
}
.dziubek {
   width: 26px;
   height: 16px;
   position: absolute;
   top: -10px;
   left: 50%;
   transform: translateX(-50%);
}

.person:hover {
   background-color: rgba(255, 253, 90, 0.35);
}
.person:hover img {
   opacity: 0.5;
}
.person:hover .name {
   opacity: 1;
}
