.wraper {
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}
@media (max-width: 768px) {
   .wraper {
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
}
