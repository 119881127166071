.nav {
   /* background-color: bisque; */
   max-width: 1200px;
   margin: 0 auto;
   padding: 1.5rem 0;
   position: sticky;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.logo {
   display: flex;
   justify-content: center;
   align-items: center;
}
.logo_img {
   max-width: 60px;
   max-height: 60px;
}
.logo_img img {
   width: 100%;
}
.link:visited {
   color: #2c2e31;
}
.text {
   font-size: 1.25rem;
   color: #2c2e31;
}

@media (max-width: 1024px) {
   .logo_img {
      max-width: 40px;
      max-height: 40px;
   }
   .text {
      font-size: 1rem;
   }
}
