section {
   position: relative;
}
.title {
   font-size: 2.5rem;
   color: #2c2e31;
   font-weight: 600;
   text-align: center;
   margin-bottom: 4rem;
   position: relative;
}
section span {
   display: block;
}

.decor {
   width: 16rem;
   height: 1rem;
   background: linear-gradient(to left, rgba(0, 179, 181, 1), rgba(255, 253, 90, 1), rgba(85, 233, 137, 0));

   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: -1;
}
.line {
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translateX(-50%);
   width: 50%;
   border-top: 1px solid rgba(44, 46, 49, 0.1);
}
@media (max-width: 768px) {
   .title {
      font-size: 1.75rem;
   }
}
