.wraper {
   max-width: 1200px;
   margin: 0 auto;
   padding: 128px 12px 0 12px;
}
.top {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
}
.title {
   font-size: 4rem;
   font-weight: 400;
   line-height: 120%;
   color: #2c2e31;
   position: relative;
   align-self: flex-start;
}
.decor {
   width: 28rem;
   height: 1rem;
   background: linear-gradient(to left, rgba(0, 179, 181, 1), rgba(255, 253, 90, 1), rgba(85, 233, 137, 0));

   position: absolute;
   top: 50px;
   left: 0;

   z-index: -1;
}
.title span {
   display: block;
}
.contact {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-end;
   font-size: 1.3rem;
}
.phone {
   margin-bottom: 0.5rem;
   color: #2c2e31;
}
.email {
   margin-bottom: 0.5rem;
   color: #2c2e31;
}
.copymail {
   background-color: rgba(0, 179, 181, 1);
   text-align: center;
   font-size: 11px;
   font-weight: 500;
   padding: 0.5rem 1rem;
   margin-bottom: 0.5rem;
}
.address {
   font-size: 1rem;
   color: rgba(44, 46, 49, 0.75);
   text-align: right;
}
.address span {
   display: block;
}

.bottom {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}
.left {
   font-size: 14px;
   color: rgba(44, 46, 49, 0.75);

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
}

.social {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 2rem;
}
.instagram:hover {
   opacity: 0.5;
}
.facebook {
   margin-left: 1rem;
}
.facebook:hover {
   opacity: 0.5;
}
.copy {
   margin-bottom: 2rem;
}
.earth img {
   max-width: 100%;
}
.right {
   font-size: 14px;
   color: rgba(44, 46, 49, 0.75);
   align-self: flex-end;
   margin-bottom: 2rem;
}

@media (max-width: 1024px) {
   .title {
      font-size: 48px;
   }
   .decor {
      width: 21rem;
      top: 35px;
   }
   .earth {
      width: 40%;
   }

   .earth img {
      width: 100%;
   }
}
@media (max-width: 768px) {
   .wraper {
      padding-top: 64px;
   }
   .top {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      text-align: center;
   }
   .title {
      align-self: center;
      margin-bottom: 4rem;
   }
   .decor {
      width: 12rem;
      top: 20px;
   }
   .contact {
      align-items: center;
      margin-bottom: 3rem;
   }

   .bottom {
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .left {
      align-items: center;
   }
   .social {
      margin-top: 0;
      margin-bottom: 3rem;
   }
   .copy {
      margin-bottom: 0.5rem;
   }
   .earth {
      width: 100%;
      max-height: 300px;
      margin-bottom: -5rem;
      order: 3;
   }
   .right {
      align-self: center;
      margin-bottom: 0;
   }
}
@media (max-width: 425px) {
   .title {
      font-size: 1.75rem;
   }
}
