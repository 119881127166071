.circle {
   width: 4rem;
   height: 4rem;
   border-radius: 50%;
   background-color: white;
   padding: 1.5rem;
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 50%);
   z-index: 1;
}
.circle img {
   width: 100%;
}
