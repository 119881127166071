.wraper {
   display: flex;
   width: 100%;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   padding: 2rem 4rem;
   background-color: white;
   border-radius: 1rem;
}
.leftColumn {
   width: 50%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   align-self: stretch;
}

.title {
   font-size: 1.75rem;
   color: #2c2e31;
   font-weight: 500;
   margin-bottom: 1.5rem;
}
.text {
   line-height: 32px;
   color: #616264;
   margin-bottom: 1rem;
}

.rightColumn {
   width: 45%;
   min-width: 253px;
   text-align: right;
}

@media (max-width: 1024px) {
   .wraper {
      padding: 2rem;
   }
}
@media (max-width: 768px) {
   .wraper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   .leftColumn {
      width: 100%;
      text-align: center;
      align-items: center;
      order: 2;
   }
   .text {
      margin-bottom: 3rem;
   }
   .rightColumn {
      width: 100%;
      text-align: center;
      margin-bottom: 3rem;
   }
}
@media (max-width: 425px) {
   .wraper {
      padding: 1rem 0.5rem;
   }
}
