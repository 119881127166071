.wraper {
   max-width: 1200px;
   padding: 32px 12px 0 12px;
   margin: 0 auto;
}
.link {
   color: rgba(0, 179, 181, 1);
   width: 15rem;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-bottom: 32px;
}
.linkArrow {
   width: 20px;
   height: 20px;
   margin-right: 1rem;
}

.linkArrow img {
   width: 100%;
}
.title {
   font-size: 4rem;
   font-weight: 500;
   color: #2c2e31;
   margin-bottom: 4rem;
}

.flexWraper {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
}
.info {
   width: 47%;
}
.sub {
   font-size: 1.75rem;
   font-weight: 600;
   color: #2c2e31;
   margin-bottom: 1.5rem;
   position: relative;
}
.decor {
   width: 33rem;
   height: 1rem;
   background: linear-gradient(to left, rgba(0, 179, 181, 1), rgba(255, 253, 90, 1), rgba(85, 233, 137, 0));

   position: absolute;
   bottom: 0;
   left: 0;
   transform: translateY(-50%);

   z-index: -1;
}
.text {
   font-size: 1rem;
   color: #616264;
   line-height: 32px;
   text-align: left;
}
.text span {
   display: block;
   margin-bottom: 1rem;
}
.phone {
   font-weight: 600;
}
.email {
   font-weight: 600;
}

.formWrap {
   width: 47%;
}

@media (max-width: 1024px) {
   .flexWraper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
   .info {
      width: 100%;
      margin-bottom: 96px;
   }
   .formWrap {
      width: 100%;
   }
}
@media (max-width: 768px) {
   .title {
      font-size: 36px;
      margin-bottom: 6rem;
   }
   .decor {
      width: 16rem;
   }
}
