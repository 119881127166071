.wraper {
   max-width: 1200px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   margin-bottom: 8rem;
}
.wraper:nth-last-child(1) {
   margin-bottom: 0;
}
.photo {
   max-width: 45%;
   border-radius: 50px;
   /* overflow: hidden; */
   position: relative;
}
.photo img {
   width: 100%;
   border-radius: 50px;
}
.box {
   width: 46%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   align-self: stretch;
}
.title {
   font-weight: 600;
   font-size: 2.5rem;
   color: #2c2e31;
   margin-bottom: 24px;
}
.text {
   line-height: 32px;
   color: #616264;
}
@media (max-width: 768px) {
   .wraper {
      flex-direction: column;
      margin-bottom: 4rem;
   }
   .photo {
      max-width: 100%;
      margin-bottom: 3rem;
   }

   .box {
      width: 100%;
   }
   .title {
      font-size: 1.75rem;
   }
   .text {
      font-size: 1rem;
   }
}
