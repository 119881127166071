.wraper {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   padding: 1rem;
   background-color: #2c2e31;
   z-index: 4;
}
.info {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 80%;
   margin: 0 auto;
}
.text {
   color: white;
   font-size: 12px;
   font-weight: 400;
   width: 75%;
   margin-bottom: 0.5rem;
}
.link {
   color: white;
   text-decoration: underline;
}
.cookieBtn {
   padding: 1rem 2rem;
   background-color: rgba(0, 179, 181, 1);
   border-radius: 50px;
   border: none;
   color: white;
   margin-left: 25px;
   cursor: pointer;
}

@media (max-width: 1024px) {
   .info {
      width: 90%;
   }
}
@media (max-width: 768px) {
   .info {
      width: 100%;
      flex-direction: column;
      justify-content: center;
   }
   .text {
      width: 100%;
   }
}
