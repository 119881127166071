@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   /* list-style: none; */
   list-style-position: inside;
   text-decoration: none;
}

body {
   margin: 0;
   font-family: "Poppins", "sans-serif";
   font-weight: 300;
}
