.button {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 1rem 2rem;
   border-radius: 50px;
   background-color: rgba(0, 179, 181, 1);
   color: white;
}

.button:hover {
   background-color: rgba(0, 141, 142, 1);
}
.arrow {
   display: block;
   width: 1.25rem;
   height: 1.25rem;
   margin-left: 16px;
}
.arrow img {
   width: 100%;
}
.btn {
   max-width: 400px;
}
