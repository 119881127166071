.wraper {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 500px;
   height: 300px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 16px;
   background-color: rgba(0, 179, 181, 1);
}
.text {
   text-align: center;
   color: white;
}
.text span {
   margin-top: 1rem;
   display: block;
}
.close {
   position: absolute;
   top: 10px;
   right: 10px;
   width: 50px;
   height: 50px;
   cursor: pointer;
}
.close img {
   width: 100%;
}

@media (max-width: 768px) {
   .wraper {
      width: 60%;
   }
}
@media (max-width: 425px) {
   .wraper {
      width: 80%;
   }
}
