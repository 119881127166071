.wraper {
   position: absolute;
   /* top: 0; */
   left: 50%;
   transform: translateX(-50%);
   width: 80vw;
   padding: 4rem 6rem;
   border-radius: 0.5rem;
   background-color: white;
   background-image: url(./../assets/images/modalBG.jpg);
   background-position: left bottom;
   background-repeat: no-repeat;

   display: flex;
   justify-content: space-between;
   align-items: center;
   z-index: 100;
}
.close {
   width: 3rem;
   height: 3rem;
   position: absolute;
   top: 20px;
   right: 20px;
   cursor: pointer;
}
.left {
   max-width: 60%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
}
.right {
   width: 25%;
   text-align: right;
}
.title {
   font-size: 2.5rem;
   font-weight: 500;
   margin-bottom: 1.5rem;
   color: #2c2e31;
}
.text {
   line-height: 32px;
   margin-bottom: 3rem;
   color: #616264;
}
.photo img {
   width: 100%;
}
@media (max-width: 1024px) {
   .wraper {
      flex-direction: column;
      padding: 3rem 1.5rem;
   }
   .left {
      max-width: 100%;
      order: 1;
   }
   .right {
      width: 100%;
      margin-bottom: 1.5rem;
   }
   .photo {
      max-width: 128px;
   }
}
@media (max-width: 768px) {
   .wraper {
      width: 95vw;
      top: 5px;
      align-items: flex-start;
      background-image: none;
   }
   .title {
      font-size: 22px;
      margin-bottom: 0.5rem;
   }
}
