.wraper {
   text-align: center;
}

.title {
   font-size: 1.75rem;
   color: #2c2e31;
   font-weight: 500;
}
.sub {
   margin-bottom: 3rem;
   color: #616264;
}
.items {
   display: flex;
   flex-direction: column;
   align-items: center;
   background-image: url(./../../assets/images/line.svg);
}
