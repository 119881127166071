.header {
   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.hero {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}
.heading_primary {
   font-size: 64px;
   color: #2c2e31;
   font-weight: 500;
   line-height: 120%;
   margin-bottom: 32px;
}
.heading_primary span {
   display: block;
}
.heading_sub {
   font-size: 22px;
   color: #616264;
   line-height: 32px;
   margin-bottom: 64px;
}
.buttons {
   width: 350px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.tree {
   max-width: 40%;
}
.tree img {
   width: 100%;
}

@media (max-width: 1024px) {
   .header {
      padding: 0 12px;
   }
   .heading_primary {
      font-size: 48px;
   }
   .heading_sub {
      font-size: 1rem;
   }
}
@media (max-width: 768px) {
   .header {
      flex-wrap: wrap;
   }
   .heading_primary {
      font-size: 36px;
   }
}
@media (max-width: 768px) {
}
@media (max-width: 425px) {
   .header {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
   }
   .hero {
      align-items: stretch;
   }
   .heading_primary {
      text-align: center;
   }
   .heading_sub {
      text-align: center;
      margin-bottom: 32px;
   }
   .buttons {
      width: 100%;
      height: 124px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
   }
   .tree {
      max-width: 100%;
   }
}
