.wraper {
   /* position: fixed; */
   width: 100vw;
   height: 100vh;
   background-color: rgb(235, 235, 162);
   padding: 24px 0;
}
.menu {
   max-width: 1200px;
   /* height: 60px; */
   margin: 0 auto;
   position: relative;
}

.close {
   width: 60px;
   height: 60px;
   cursor: pointer;

   position: absolute;
   top: 0;
   right: 0;
}
.close img {
   width: 100%;
}
.list {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100%;
   transform: translate(-50%, -50%);
   margin: 0 auto;
   text-align: center;
   list-style: none;

   font-size: 24px;
}
