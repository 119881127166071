.wraper {
   margin: 0 auto;
   max-width: 42rem;
   text-align: center;
}
.photo {
   width: 8rem;
   height: 8rem;
   margin: 0 auto;
   border-radius: 50%;
   overflow: hidden;
   margin-bottom: 1.5rem;
}
.title {
   margin-bottom: 1.5rem;
}
.sub {
   color: #616264;
}
.name {
   font-size: 1.75rem;
   font-weight: 500;
   color: #2c2e31;
}
.text {
   line-height: 32px;
   color: #616264;
}
