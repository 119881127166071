.wraper {
   max-width: 1200px;

   margin: 0 auto;
   display: flex;
   justify-content: space-between;
   align-items: center;

   margin-bottom: 5rem;
}

.arrowLeft {
   width: 6rem;
   height: 6rem;
   border-radius: 50%;
   background-color: rgba(0, 179, 181, 1);
   cursor: pointer;

   display: flex;
   justify-content: center;
   align-items: center;
}
.arrowLeft:hover {
   background-color: rgba(0, 141, 142, 1);
}

.text {
   max-width: 33rem;
   /* height: 14rem; */
}
.arrowRight {
   width: 6rem;
   height: 6rem;
   border-radius: 50%;
   background-color: rgba(0, 179, 181, 1);
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
.arrowRight:hover {
   background-color: rgba(0, 141, 142, 1);
}

.steps {
   display: flex;
   justify-content: space-between;
   align-items: center;
   max-width: 40%;
   margin: 0 auto;

   /* border: 1px solid black; */

   position: relative;
   z-index: 1;
}

.bar {
   position: absolute;
   top: 50%;
   left: 0;
   transform: translateY(-50%);
   width: 100%;
   height: 50%;
   border-radius: 50px;
   overflow: hidden;
   z-index: 0;
}
.fill {
   background: linear-gradient(to right, rgba(255, 236, 191, 1), rgba(255, 236, 191, 0.5));
   width: 0;
   height: 100%;
}

@media (max-width: 768px) {
   .wraper {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0;
   }
   .text {
      max-width: 100%;
      margin-bottom: 2rem;
   }
   .arrowLeft {
      order: 1;
      margin-right: 0.5rem;
   }
   .arrowRight {
      order: 2;
      margin-left: 0.5rem;
   }
}
