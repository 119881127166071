.wraper {
   max-width: 1200px;

   margin: 0 auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.list_posts {
   max-width: 50rem;
}

.arrowLeft {
   width: 6rem;
   height: 6rem;
   border-radius: 50%;
   background-color: rgba(0, 179, 181, 1);
   cursor: pointer;

   display: flex;
   justify-content: center;
   align-items: center;
}
.arrowLeft:hover {
   background-color: rgba(0, 141, 142, 1);
}

.arrowRight {
   width: 6rem;
   height: 6rem;
   border-radius: 50%;
   background-color: rgba(0, 179, 181, 1);
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
.arrowRight:hover {
   background-color: rgba(0, 141, 142, 1);
}

@media (max-width: 1024px) {
   .wraper {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0;
   }
   .list_posts {
      max-width: 100%;
      margin-bottom: 2rem;
   }
   .arrowLeft {
      order: 1;
      margin-right: 0.5rem;
   }
   .arrowRight {
      order: 2;
      margin-left: 0.5rem;
   }
}
