.wraper {
   width: 100%;
   background-color: white;
   border-radius: 1rem;
   padding: 3rem;
   text-align: center;
}
.title {
   font-size: 1.75rem;
   font-weight: 500;
   color: #2c2e31;
   margin-bottom: 1.5rem;
}
.text {
   line-height: 32px;
   color: #616264;
}

/* @media (max-width: 1024px) {
   .wraper {
      padding: 1rem;
   }
} */
