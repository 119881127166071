.wraper {
   max-width: 1200px;
   margin: 0 auto;
   text-align: center;
}
.logoWraper {
   display: flex;
   padding: 24px 0;
}
.logo {
   display: flex;
   justify-content: center;
   align-items: center;
}
.logo_img {
   max-width: 60px;
   max-height: 60px;
}
.logo_img img {
   width: 100%;
}
.link:visited {
   color: #2c2e31;
}
.textLogo {
   font-size: 1.25rem;
   color: #2c2e31;
}
.title {
   display: flex;
   justify-content: center;
   align-self: center;
   font-size: 192px;
   color: #55e989;
   text-align: center;
   /* line-height: 110%; */
   margin-bottom: 2rem;
}
.four {
   display: block;
   transform: rotate(30deg);
}
.text {
   font-size: 4rem;
   font-weight: 500;
   line-height: 120%;
   text-align: center;
   margin-bottom: 2rem;
}
.sub {
   display: block;
   font-size: 22px;
   font-weight: 400;
   line-height: 24px;
   color: #616264;
   margin-bottom: 2rem;
}
.btn {
   display: inline-block;
}

@media (max-width: 1024px) {
   .wraper {
      padding: 0 12px;
   }
   .logo_img {
      max-width: 40px;
      max-height: 40px;
   }
   .textLogo {
      font-size: 1rem;
   }
}
@media (max-width: 768px) {
   .title {
      font-size: 128px;
   }
   .text {
      font-size: 36px;
   }
   .sub {
      font-size: 1rem;
   }
}
@media (max-width: 425px) {
   .btn {
      width: 100%;
   }
}
